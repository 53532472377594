import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal, Chip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig'
import { universityMap, facultyMap, departmentMap } from '../config/subject_mapconfig';

Chart.register(ArcElement, Tooltip, Legend);

const CourseModal = ({ open, handleClose, course, selectedUniversity, selectedFaculty, selectedDepartment }) => {
  const [hasVoted, setHasVoted] = useState(false);

  useEffect(() => {
    if (course) {
      const voted = localStorage.getItem(`vote_${course.courseCode}`);
      setHasVoted(!!voted);
    }
  }, [course, selectedDepartment, selectedFaculty, selectedUniversity]);

  const totalVotes = course.votes ? (course.votes.easy + course.votes.normal + course.votes.hard) : 0;
  const hardPercentage = totalVotes ? Math.round((course.votes.hard / totalVotes) * 100) : 0;
  const normalPercentage = totalVotes ? Math.round((course.votes.normal / totalVotes) * 100) : 0;
  const easyPercentage = totalVotes ? Math.round((course.votes.easy / totalVotes) * 100) : 0;

  if (!course) return null;

  const data = {
    labels: ['エグ単', '普通', '楽単'],
    datasets: [
      {
        data: totalVotes ? [hardPercentage, normalPercentage, easyPercentage] : [0, 0, 0],
        backgroundColor: ['#d32f2f', '#00FF57', '#1976d2'],
        hoverBackgroundColor: ['#d32f2f', '#00FF57', '#1976d2']
      }
    ]
  };

  const options = {
    rotation: -90,
    circumference: 180,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 18,
            weight: 'bold'
          },
          padding: 16
        }
      }
    }
  };

  const handleVote = async (voteType) => {
    const university = universityMap[selectedUniversity];
    const faculty = facultyMap[selectedFaculty];
    const department = departmentMap[selectedDepartment];
    if (!course || !university || !faculty || !department) {
      console.error("Invalid collection path");
      return;
    }

    try {
      const courseRef = doc(db, `${university}/${faculty}/${department}`, course.id);

      let docData = {};

      switch (voteType) {
        case "easy":
          docData = { "votes.easy": (course.votes?.easy || 0) + 1 };
          break;
        case "normal":
          docData = { "votes.normal": (course.votes?.normal || 0) + 1 };
          break;
        case "hard":
          docData = { "votes.hard": (course.votes?.hard || 0) + 1 };
          break;
        default:
          break;
      }

      await updateDoc(courseRef, docData);

      localStorage.setItem(`vote_${course.courseCode}`, voteType);
      setHasVoted(true);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleRemoveVote = () => {
    localStorage.removeItem(`vote_${course.courseCode}`);
    setHasVoted(false);
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 2,
        borderRadius: 1,
        overflowY: 'auto',
        maxHeight: '80vh'
      }}>
        <Box display="flex" justifyContent="right" alignItems="center">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={{ fontWeight: 600, fontSize: 30 }} variant="h6" component="div" gutterBottom>
          {course.courseName}
        </Typography>
        <Typography sx={{ fontWeight: 600, fontSize: 20 }} variant="h6" component="div" gutterBottom>
          {course.lecturer}
        </Typography>
        <Box mb={2}>
          {totalVotes > 0 ? (
            <Pie data={data} options={options} />
          ) : (
            <Typography sx={{ fontSize: 18, color: 'gray' }}>投票データなし</Typography>
          )}
        </Box>
        <Box mt={4} display="flex" flexDirection="column" alignItems="flex-start">
          {totalVotes > 0 && (
            <>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#d32f2f' }}>エグ単: </Typography>
                <Typography sx={{ fontSize: 18, ml: 1 }}>{hardPercentage}%</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#00FF57' }}>普通: </Typography>
                <Typography sx={{ fontSize: 18, ml: 1 }}>{normalPercentage}%</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#1976d2' }}>楽単: </Typography>
                <Typography sx={{ fontSize: 18, ml: 1 }}>{easyPercentage}%</Typography>
              </Box>
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="flex-start" mt={2}>
          {hasVoted ? (
            <>
              <Typography sx={{ fontSize: 18, fontWeight: 'bold', mt: 1 }}>
                投票完了です！ありがとう！
              </Typography>
              <Chip
                label="投票を取り消す"
                onClick={handleRemoveVote}
                sx={{ fontSize: 20, fontWeight: 700, padding: '10px', backgroundColor: '#ff9800', color: 'white', marginTop: '20px' }}
              />
            </>
          ) : (
            <>
              <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }}>
                あなたも投票してね！この科目は
              </Typography>
              <Box display="flex" justifyContent="center">
                <Chip
                  label="エグ単"
                  onClick={() => handleVote('hard')}
                  sx={{
                    fontSize: 20,
                    fontWeight: 700,
                    padding: '10px',
                    backgroundColor: '#d32f2f',
                    color: 'white',
                    margin: '10px',
                    '&:hover':{
                      backgroundColor:'#8b0000'
                    }
                  }}
                />
                <Chip
                  label="普通"
                  onClick={() => handleVote('normal')}
                  sx={{
                    fontSize: 20,
                    fontWeight: 700,
                    padding: '10px',
                    backgroundColor: '#00FF57',
                    color: 'black',
                    margin: '10px',
                    '&:hover':{
                      backgroundColor:'#3cb371'
                    }

                  }}
                />
                <Chip
                  label="楽単"
                  onClick={() => handleVote('easy')}
                  sx={{
                    fontSize: 20,
                    fontWeight: 700,
                    padding: '10px',
                    backgroundColor: '#1976d2',
                    color: 'white',
                    margin: '10px',
                    '&:hover':{
                      backgroundColor:'#00008b'
                    }
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CourseModal;