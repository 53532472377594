import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404  Page Not Found</h1>
      <h3>お探しのページは見つかりませんでした。</h3>
      <Button
        variant="contained"
        
        component={Link}
        to="/"
        sx={{
          background:"#8b008b",
          fontWeight: 600,
          fontSize: 20,
          mt: 2,
          px: 10,
          borderRadius: 10,
          '&:hover': {
              backgroundColor: '#660066', // ホバーカラーを指定
            }
        }}

      >
        ホームに戻る
      </Button>
    </div>
  );
}

export default NotFound;