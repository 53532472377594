import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CourseContext } from '../config/context';
import { useCourses } from '../config/context';

const GuideMobileApp = () => {
    const navigate = useNavigate();
    const { currentOS } = useCourses(CourseContext);

    const handleStart = () => {
        localStorage.setItem('first', 'true');
        navigate('/general-courses');
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vH" >

            <Typography
                sx={{
                    fontSize: 18
                }}
                variant="body1"
                mt={2}
            >
                専門科目を見るにはアプリが必要です
            </Typography>

            {currentOS === "iOS" || currentOS === "Android" ? (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            switch (currentOS) {
                                case "iOS":
                                    window.open('https://apps.apple.com/jp/app/%E9%9D%9E%E5%85%AC%E5%BC%8F-%E5%90%8C%E5%BF%97%E7%A4%BE%E6%A5%BD%E5%8D%98%E3%81%BE%E3%81%A8%E3%82%81/id6651855136', '_blank');
                                    break;
                                case "Android":
                                    window.open('https://play.google.com/store/apps/details?id=com.milicone.dorakutan', '_blank');
                                    break;
                                default:
                                    break;
                            }
                        }}
                        to="/"
                        sx={{
                            background: "#8b008b",
                            fontWeight: 600,
                            fontSize: 18,
                            width: 200,
mt:3,
                            borderRadius: 10,
                            '&:hover': {
                                backgroundColor: '#660066', // ホバーカラーを指定
                            }
                        }}
                    >
                        アプリを使う
                    </Button>
                </>
            ) : null

            }

            <Button
                onClick={handleStart}
                to="/"
                sx={{
                    mt: 4,
                    background: 'transparent',
                    fontWeight: 500,
                    color: '#8b008b',
                    fontSize: 16,
                    width: 200,
                    borderRadius: 10,
                    '&:hover': {
                        backgroundColor: 'transparent', // ホバーカラーを指定
                    }
                }}
            >
                 般教に戻る
            </Button>

        </Box>
    );
};

export default GuideMobileApp;