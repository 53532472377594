import React, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Button } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { universityMap, facultyMap, departmentMap } from '../config/subject_mapconfig';
import { useGeneralCourses } from '../config/context';

Chart.register(ArcElement, Tooltip, Legend);

const MobileGeneralCoursePage = () => {
    const [hasVoted, setHasVoted] = useState(false);
    const { selectedUniversity, selectedField } = useGeneralCourses()
    const location = useLocation();
    const { course } = location.state || {}; // デフォルト値を指定

    const navigate = useNavigate();

    useEffect(() => {
        const voted = getBoolFromLocalStorage(`vote_${course.courseCode}`);
        setHasVoted(voted);
        if (!course) {
            console.error("Course data is not available");
            return;
        }
    }, [course]);

    // course.votes が存在しない場合の初期値設定
    const votes = course?.votes || { easy: 0, normal: 0, hard: 0 };

    const hardVotes = votes.hard ?? 0; // null または undefined の場合 0 を設定
    const normalVotes = votes.normal ?? 0;
    const easyVotes = votes.easy ?? 0;

    const totalVotes = hardVotes + normalVotes + easyVotes;

    const hardPercentage = totalVotes ? Math.round((hardVotes / totalVotes) * 100) : 0;
    const normalPercentage = totalVotes ? Math.round((normalVotes / totalVotes) * 100) : 0;
    const easyPercentage = totalVotes ? Math.round((easyVotes / totalVotes) * 100) : 0;

    if (!course) return null

    const data = {
        labels: ['エグ単', '普通', '楽単'],
        datasets: [
            {
                data: totalVotes ? [hardPercentage, normalPercentage, easyPercentage] : [0, 0, 0],
                backgroundColor: ['#d32f2f', '#00FF57', '#1976d2'],
                hoverBackgroundColor: ['#d32f2f', '#00cc00', '#1976d2']
            }
        ]
    };

    const options = {
        rotation: -90,
        circumference: 180,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                    padding: 8
                }
            }
        }
    };

    const handleVote = async (voteType) => {
        const university = universityMap[selectedUniversity];
        const faculty = facultyMap['一般教養科目'];
        const field = departmentMap[selectedField];
        if (!course || !university || !faculty || !field) {
            // console.error("Invalid collection path");
            return;
        }

        try {
            const courseRef = doc(db, `${university}/${faculty}/${field}`, course.id);

            let docData = {};

            switch (voteType) {
                case "easy":
                    docData = { "votes.easy": (votes.easy || 0) + 1 };
                    break;
                case "normal":
                    docData = { "votes.normal": (votes.normal || 0) + 1 };
                    break;
                case "hard":
                    docData = { "votes.hard": (votes.hard || 0) + 1 };
                    break;
                default:
                    break;
            }

            await updateDoc(courseRef, docData);

            localStorage.setItem(`vote_${course.courseCode}`, true);
            setHasVoted(true);
        } catch (error) {
            // console.error("Error updating document: ", error);
        }
    };

    // ローカルストレージからbool値を読み取る関数
    const getBoolFromLocalStorage = (key) => {
        try {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : false; // 値がnullならfalseを返す
        } catch (error) {
            // console.error('Error reading from local storage', error);
            return false;
        }
    };

    const handleRemoveVote = () => {
        localStorage.removeItem(`vote_${course.courseCode}`);
        setHasVoted(false);
    };

    return (
        <Box sx={{ pt: 2, maxWidth: '100%' }}>
            <Button onClick={() => navigate(-1)} sx={{fontSize:18}}>
                閉じる
            </Button>
            <Typography sx={{ pt: 2, fontWeight: 600 }} variant="h6" component="div" gutterBottom>
                {course.courseName}
            </Typography>

            <Typography sx={{ fontWeight: 600, }} variant="h6" component="div" gutterBottom>
                {course.lecturer}
            </Typography>
            <Box mb={2} sx={{
                mx:6
            }}>
                {totalVotes > 0 ? (
                    <Pie  data={data} options={options} />
                ) : (
                    <Typography sx={{ fontSize: 18, color: 'gray' }}>投票データなし</Typography>
                )}
            </Box>
            <Box mt={4} display="flex" flexDirection="column" alignItems="flex-start">
                {totalVotes > 0 && (
                    <>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#d32f2f' }}>エグ単: </Typography>
                            <Typography sx={{ fontSize: 18, ml: 1 }}>{hardPercentage}%</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>普通: </Typography>
                            <Typography sx={{ fontSize: 18, ml: 1 }}>{normalPercentage}%</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#1976d2' }}>楽単: </Typography>
                            <Typography sx={{ fontSize: 18, ml: 1 }}>{easyPercentage}%</Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="flex-start" mt={2}>
                {hasVoted ? (
                    <>
                        <Typography sx={{ fontSize: 18, fontWeight: 'bold', mt: 1 }}>
                            投票完了です！ありがとう！
                        </Typography>
                        <Typography sx={{ fontSize: 18, fontWeight: 'bold', mt: 1 }}>
                            (結果は数時間後に反映されます)
                        </Typography>
                        <Chip
                            label="投票を取り消す"
                            onClick={handleRemoveVote}
                            sx={{ fontSize: 20, fontWeight: 700, padding: '10px', backgroundColor: '#ff9800', color: 'white', marginTop: '20px' }}
                        />
                    </>
                ) : (
                    <>
                        <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }}>
                            あなたも投票してね！この科目は
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Chip
                                label="エグ単"
                                onClick={() => handleVote('hard')}
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 700,
                                    padding: 2,
                                    backgroundColor: '#d32f2f',
                                    color: 'white',
                                    margin: '3px',
                                    '&:hover': {
                                        backgroundColor: '#8b0000'
                                    }
                                }}
                            />
                            <Chip
                                label="普通"
                                onClick={() => handleVote('normal')}
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 700,
                                    padding: 2,
                                    backgroundColor: '#00cc00',
                                    color: 'black',
                                    margin: '3px',
                                    '&:hover': {
                                        backgroundColor: '#3cb371'
                                    }

                                }}
                            />
                            <Chip
                                label="楽単"
                                onClick={() => handleVote('easy')}
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 700,
                                    padding: 2,
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    margin: '3px',
                                    '&:hover': {
                                        backgroundColor: '#00008b'
                                    }
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box >
    );
};

export default MobileGeneralCoursePage;