import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FirstPage = () => {
    const navigate = useNavigate();

    const handleStart = () => {
        // localStorage.setItem('first', 'true');
        navigate('/second');
    };


    const openRule = () => {
        navigate('/term');
    };

    const openPrivacyPolicy = () => {
        navigate('/privacy-policy');
    };

    const openContact = () => {
        navigate('/help');
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
            <Typography sx={{ fontWeight: 600 }} variant="h5">(非公式) </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="h5">同志社楽単まとめサイト</Typography>
            <Typography sx={{ fontSize: 18 }} mt={4}>皆で「エグ単・普通・楽単」を</Typography>
            <Typography sx={{ fontSize: 18 }} >投票・閲覧するサービス！</Typography>

            <Button
                variant="contained"
                onClick={handleStart}
                to="/"
                sx={{
                    background: "#8b008b",
                    fontWeight: 600,
                    fontSize: 20,
                    mt: 2,
                    px: 10,
                    borderRadius: 10,
                    '&:hover': {
                        backgroundColor: '#660066', // ホバーカラーを指定
                    }
                }}
            >
                次へ
            </Button>

            <Box display="flex" justifyContent="center" mt={5}>
                <Button onClick={openRule} sx={{ fontSize: 14, textTransform: 'none' }}>
                    利用規約
                </Button>
                <Typography variant="body1" sx={{ fontSize: 14, mt: 1 }}>と</Typography>
                <Button onClick={openPrivacyPolicy} sx={{ fontSize: 14, textTransform: 'none' }}>
                    プライバシーポリシー
                </Button>
            </Box>
            <Typography sx={{ fontSize: 14 }}>
                に同意したものとします
            </Typography>
            <Typography sx={{ fontSize: 14, mt: 2 }}>※同志社大学が公式に運営しているものではありません。</Typography>

            <Box
                sx={{
                    position: 'fixed',
                    top: 16,
                    left: 0,
                    right: 0,
                    textAlign: 'right',
                }}
            >
                <Button
                    
                    onClick={openContact}
                    sx={{color:'black', borderRadius: 25, px: 4 }}
                >
                    ヘルプ
                </Button>
            </Box>
        </Box>
    );
};

export default FirstPage;