import { getDocs, query, collection, where, addDoc, or, orderBy } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { universityMap, facultyMap, departmentMap, fieldMap } from './subject_mapconfig';

export const fetchCourses = async (selectedUniversity, selectedFaculty, selectedDepartment, selectedTerm) => {
  const university = universityMap[selectedUniversity];
  const faculty = facultyMap[selectedFaculty] || "未選択";
  const department = departmentMap[selectedDepartment] || "未選択";


  if (!university || !faculty || !department || !selectedTerm) {
    throw new Error("Invalid university, faculty, department, or term");
  }
  console.log("load");
  const courses = [];

  if (faculty === "未選択" || department === "未選択") {

  } else {
    try {
      var term = selectedTerm;

      if (term === "通年") {
        term = "両方"
      }

      var q = query(
        collection(db, `${university}/${faculty}/${department}`),
        or(where('semester', '==', term), where('semester', '==', '両方')),
        orderBy("courseName"),
      );

      if (term === "全部") {
        q = query(
          collection(db, `${university}/${faculty}/${department}`),
          orderBy("courseName"),
        );
      }

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        courses.push({ id: doc.id, ...doc.data() });
      });

      if (department === "Law" || department || "PoliticalScience") {
        var lawQuery = query(collection(db, `${university}/${faculty}/Commons`),
          or(where('semester', '==', term), where('semester', '==', '両方')),
          orderBy("courseName"),

          // or(where('semester', '==', term), where('semester', '==', '不明'))
        )

        if (term === "全部") {
          lawQuery = query(
            collection(db, `${university}/${faculty}/${department}`),
            orderBy("courseName"),
          );
        }


        const lawQuerySnapshot = await getDocs(lawQuery);

        lawQuerySnapshot.forEach((doc) => {
          courses.push({ id: doc.id, ...doc.data() })
        })

        return courses;
      } else {
        return courses;
      }

    } catch (error) {
      console.error("Error fetching courses: ", error);
      throw new Error("データの取得に失敗しました。" + error);
    }
  }
};


export const fetchGeneralCourses = async (selectedUniversity, selectedField, selectedTerm) => {
  const university = universityMap[selectedUniversity];
  const field = fieldMap[selectedField];

  if (!university || !field || !selectedTerm) {
    throw new Error("Invalid university, faculty, department, or term");
  }

  const courses = [];

  try {
    var term = selectedTerm;

    if (term === "通年") {
      term = "両方"
    }

    var q = query(
      collection(db, `${university}/General/${field}`),
      or(where('semester', '==', term), where('semester', '==', '両方')),
      orderBy("courseName"),
    );

    if (term === "全部") {
      q = query(
        collection(db, `${university}/General/${field}`),
        orderBy("courseName"),
      );
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      courses.push({ id: doc.id, ...doc.data() });
    });

    return courses;

  } catch (error) {
    throw new Error("データの取得に失敗しました。" + error);
  }
};

export const addCourseToFirestore = async (selectedUniversity, selectedFaculty, selectedDepartment, course) => {
  const university = universityMap[selectedUniversity];
  const faculty = facultyMap[selectedFaculty];
  const department = departmentMap[selectedDepartment];

  try {
    const docRef = await addDoc(collection(db, `${university}/${faculty}/${department}`), course);
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e;
  }
};