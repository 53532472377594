import { Box, Typography, Select, MenuItem, Button } from '@mui/material';
import { departments } from '../config/subject_mapconfig';
import { useCourses } from '../config/context';
import { useNavigate } from 'react-router-dom';

const MobileHeader = () => {
    const {
        selectedUniversity,
        // setSelectedUniversity,
        selectedFaculty,
        setSelectedFaculty,
        selectedDepartment,
        setSelectedDepartment,
        term,
        setTerm,
        selectedFaculties,

    } = useCourses();

    const isDoshisha = selectedUniversity === '同志社';
    const mainColor = isDoshisha ? '#800080' : '#9b59b6';
    const navigate = useNavigate();
    // const hoverColor = isDoshisha ? '#b83fb3' : '#b3a1c7';


    const handleFacultyChange = async (event) => {
        const selectedFaculty = event.target.value;
        setSelectedFaculty(selectedFaculty);
        const departmentList = departments[selectedFaculty];
        if (departmentList.length === 1) {
            setSelectedDepartment(departmentList[0]);
        } else {
            setSelectedDepartment('');
        }

    };
  

    const handleSignUpClick = () => {
        navigate('/general-courses');
    }

    const handleDepartmentChange = async (event) => {
        const newDepartment = event.target.value;
        setSelectedDepartment(newDepartment);
    };

    const handleTermChange = async (newTerm) => {
        setTerm(newTerm);
    };

    const departmentsData = departments[selectedFaculty] || [];

    return (
        <Box
            sx={{
                mt: 2,
            }}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3
                }}
            >
                <Typography
                    sx={{
                        textAlign: 'left',
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    (非公式) {selectedUniversity}楽単まとめ
                </Typography>

                <Button
                    variant="contained"
                    sx={{
                        width: 150,
                        height: 46,
                        fontSize: '16px',
                        fontWeight: 600,
                        backgroundColor: mainColor,
                        color: 'white',
                        borderRadius: '25px',
                        ml: 2,
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#6a006a',
                        },
                    }}
                    onClick={handleSignUpClick}
                >
                    般教科目を見る
                </Button>
            </Box>

            <Box
                width="99%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={2}
                bgcolor="#ffffff"
                boxShadow={2}
                borderRadius="10px"
            >
                {/* <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Box sx={{
                        position: 'relative',
                        width: '90%',
                        height: '37px',
                        backgroundColor: '#ececec',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 5px',
                    }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '49%',
                                height: '80%',
                                backgroundColor: mainColor,
                                borderRadius: '20px',
                                transition: 'transform 0.3s',
                                zIndex: 0,
                                transform: isDoshisha ? 'translateX(0)' : 'translateX(100%)',
                            }}
                            onClick={() => setSelectedUniversity(isDoshisha ? '同女' : '同志社')}
                        ></Box>

                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '50%',
                                textAlign: 'center',
                                userSelect: 'none',
                                color: isDoshisha ? 'white' : 'black',
                            }}
                            onClick={() => setSelectedUniversity('同志社')}
                        >
                            同志社
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '50%',
                                textAlign: 'center',
                                userSelect: 'none',
                                color: !isDoshisha ? 'white' : 'black',
                            }}
                            onClick={() => setSelectedUniversity('同女')}
                        >
                            同女
                        </Box>
                    </Box>
                </Box>
 */}

                <Box
                    sx={{
                        width: "100%"
                    }}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    marginLeft="30px"
                    mb={1}
                >
                    <Typography>学部</Typography>
                    <Select
                        value={selectedFaculty}
                        onChange={handleFacultyChange}
                        sx={{
                            fontSize: 14,
                            borderRadius: '20px',
                            backgroundColor: '#ececec',
                            marginLeft: '10px',
                            height: '30px',
                        }}
                    >
                        {selectedFaculties.map((faculty) => (
                            <MenuItem
                                sx={{
                                    fontSize: 14,
                                }}
                                key={faculty}
                                value={faculty}>
                                {faculty}
                            </MenuItem>
                        ))}
                    </Select>

                </Box>

                <Box
                    sx={{
                        width: "100%"
                    }}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    marginLeft="30px"
                    mb={2}
                >
                    <Typography>学科</Typography>
                    <Select
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                        sx={{
                            fontSize: 13,
                            borderRadius: '20px',
                            backgroundColor: '#ececec',
                            height: '30px',
                            marginLeft: '10px',
                        }}
                        disabled={!selectedFaculty}
                    >
                        {departmentsData.map((department) => (
                            <MenuItem
                                sx={{
                                    fontSize: 14,
                                }}
                                key={department} value={department}>
                                {department}
                            </MenuItem>
                        ))}
                    </Select>

                </Box>

                <Box
                    sx={{
                        width: "100%"
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        sx={{
                            position: 'relative',
                            width: '90%',
                            height: '40px',
                            backgroundColor: '#ececec',
                            borderRadius: '20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 10px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '24%',
                                height: '80%',
                                backgroundColor: mainColor,
                                borderRadius: '20px',
                                transition: 'transform 0.3s',
                                zIndex: 0,
                                transform:
                                    term === '春学期'
                                        ? 'translateX(0%)'
                                        : term === '秋学期'
                                            ? 'translateX(100%)'
                                            : term === '通年'
                                                ? 'translateX(200%)'
                                                : 'translateX(300%)',
                            }}
                            onClick={() =>
                                handleTermChange(
                                    term === '春学期'
                                        ? '秋学期'
                                        : term === '秋学期'
                                            ? '通年'
                                            : term === '通年'
                                                ? '全部'
                                                : '春学期'
                                )
                            }
                        ></Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '春学期' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('春学期')}
                        >
                            春学期
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '秋学期' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('秋学期')}
                        >
                            秋学期
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '通年' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('通年')}
                        >
                            通年
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '全部' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('全部')}
                        >
                            全部
                        </Box>
                    </Box>
                </Box>
                {(term === "通年") ? (
                    <Box>
                        <Typography sx={{
                            color: 'red'
                        }}>
                            同じ科目で春と秋に別々に開講される科目も含まれます
                        </Typography>
                    </Box>
                ) : null}
            </Box>

        </Box>
    );
};

export default MobileHeader;