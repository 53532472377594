import React, { useEffect } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { departments } from '../config/subject_mapconfig';
import { useCourses } from '../config/context';

const UnderHeader = () => {
  const {
    selectedUniversity,
    selectedFaculty,
    selectedFaculties,
    setSelectedFaculty,
    selectedDepartment,
    setSelectedDepartment,
    term,
    setTerm,

  } = useCourses();  // CourseContext から状態を取得

  const isDoshisha = selectedUniversity === '同志社';
  const mainColor = isDoshisha ? '#800080' : '#9b59b6';

  const handleFacultyChange = async (event) => {
    const selectedFaculty = event.target.value;
    setSelectedFaculty(selectedFaculty);
  };

  const handleDepartmentChange = async (event) => {
    const newDepartment = event.target.value;
    setSelectedDepartment(newDepartment);

  };

  const handleTermChange = async (newTerm) => {
    setTerm(newTerm);
  };

  useEffect(() => {
    if (!selectedFaculty && selectedFaculties.length > 0) {
      setSelectedFaculty(selectedFaculties[0]);
    }
  }, [selectedFaculty, setSelectedFaculty, selectedFaculties]);

  const departmentsData = departments[selectedFaculty] || [];

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>

         <Box display="flex" alignItems="center" mx={2}>
          <Box display="flex" alignItems="center" mx={2}>
            <Typography variant="body1">学部：</Typography>
            <Select
              value={selectedFaculty}
              onChange={handleFacultyChange}
              sx={{
                borderRadius: '25px',
                backgroundColor: '#E0E0E0',
                padding: '5px 10px',
                height: '50px',
                marginLeft: '10px'
              }}
            >
              {Array.isArray(selectedFaculties) && selectedFaculties.map((faculty) => (
                <MenuItem key={faculty} value={faculty}>
                  {faculty}
                </MenuItem>
              ))}

            </Select>
          </Box>

          <Box display="flex" alignItems="center" mx={2}>
            <Typography variant="body1">学科：</Typography>
            <Select
              value={selectedDepartment}
              onChange={handleDepartmentChange}
              sx={{
                borderRadius: '25px',
                backgroundColor: '#E0E0E0',
                padding: '5px 10px',
                height: '50px',
                marginLeft: '10px'
              }}

            >
              {departmentsData.map((department) => (
                <MenuItem key={department} value={department}>
                  {department}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mx={2}>
          <Box
            sx={{
              position: 'relative',
              width: '400px',
              height: '50px',
              backgroundColor: '#E0E0E0',
              borderRadius: '25px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 10px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '24%',
                height: '80%',
                backgroundColor: mainColor,
                borderRadius: '25px',
                transition: 'transform 0.3s',
                zIndex: 0,
                transform: term === '春学期'
                  ? 'translateX(0%)'
                  : term === '秋学期'
                    ? 'translateX(100%)'
                    : term === '通年'
                      ? 'translateX(200%)'
                      : 'translateX(300%)',
              }}
              onClick={() => handleTermChange(
                term === '春学期'
                  ? '秋学期'
                  : term === '秋学期'
                    ? '通年'
                    : term === '通年'
                      ? '全部'
                      : '春学期'
              )}
            ></Box>
            <Box
              sx={{
                zIndex: 1,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 700,
                width: '50%',
                textAlign: 'center',
                color: term === '春学期' ? 'white' : 'black',
              }}
              onClick={() => handleTermChange('春学期')}
            >
              春学期
            </Box>
            <Box
              sx={{
                zIndex: 1,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 700,
                width: '50%',
                textAlign: 'center',
                color: term === '秋学期' ? 'white' : 'black',
              }}
              onClick={() => handleTermChange('秋学期')}
            >
              秋学期
            </Box>
            <Box
              sx={{
                zIndex: 1,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 700,
                width: '50%',
                textAlign: 'center',
                color: term === '通年' ? 'white' : 'black',
              }}
              onClick={() => handleTermChange('通年')}
            >
              通年
            </Box>
            <Box
              sx={{
                zIndex: 1,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 700,
                width: '50%',
                textAlign: 'center',
                color: term === '全部' ? 'white' : 'black',
              }}
              onClick={() => handleTermChange('全部')}
            >
              全部
            </Box>
          </Box>
        </Box>

      </Box >
      {(term === "通年") ? (
        <Box justifyContent="center" alignItems="center">
          <Typography sx={{
            color: 'red'
          }}>
            同じ科目で春と秋に別々に開講される科目も含まれます
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default UnderHeader;