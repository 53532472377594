export const universityMap = {
    '同志社': 'doshisha',
    '同女': 'dwcla'
  };
  
  export const facultyMap = {
    '文学部': 'Letters',//
    '神学部': 'Theology',//
    '法学部': 'Law',//
    '経済学部': 'Economics',//
    '商学部': 'Commerce',//
    '社会学部': 'SocialStudies',//
    '政策学部': 'PolicyStudies',
    'グローバル地域文化学部': 'GlobalAndRegionalStudies',//
    '理工学部': 'ScienceAndEngineering',//
    '生命医科学部': 'LifeAndMedicalSciences',//
    'スポーツ健康科学部': 'HealthAndSportsScience',//
    '文化情報学部': 'CultureAndInformationScience',//
    '心理学部': 'Psychology',
    'グローバル・コミュニケーション学部': 'GlobalCommunication',
  
    // 同女
    '学芸学部': 'LiberalArts',
    '現代社会学部': 'ContemporarySocialStudies',
    '薬学部': 'PharmaceuticalSciences',
    '看護学部': 'Nursing',
    '生活科学部': 'HumanLifeScience',
    '表象文化学部': 'RepresentationCulture',

    '一般教養科目':'General'

  };
  
  export const fieldMap = {
    '同志社科目': 'DoshishaSubjects',
    'キャリア形成支援科目': 'CareerFormationSupportSubjects',
    '国際教養科目': 'InternationalLiberalArtsSubjects',
    'クリエイティブジャパン科目': 'CreativeJapanSubjects',
    '人文科学系科目': 'HumanitiesSubjects',
    '社会科学系科目':'SocialScienceCourses',
    '自然人間科学系科目': 'NaturalAndHumanSciencesSubjects',
    '複合領域科目': 'InterdisciplinarySubjects',
    'プロジェクト科目': 'ProjectSubjects',
    '保健体育科目': 'HealthAndPhysicalEducationSubjects',
  }
  
  export const departmentMap = {
    // 文学部
    '英文学科': 'EnglishLiterature',//
    '哲学科': 'Philosophy',//
    '文化史学科': 'CulturalHistory',//
    '国文学科': 'JapaneseLiterature',
    '美学芸術学科': 'AestheticsAndArt',//
  
    // 神学部
    '神学科': 'Theology',//
  
    // 法学部
    '政治学科': 'PoliticalScience',//
    '法律学科': 'Law',//
  
    // 経済学部
    '経済学科': 'Economics',//
  
    // 商学部
    '商学科': 'Commerce',//隣接科目以外
  
    // 社会学部
    '社会学科': 'Sociology',//
    '社会福祉学科': 'SocialWelfare',//
    'メディア学科': 'MediaStudies',
    '産業関係学科': 'IndustrialRelations',
    '教育文化学科': 'EducationAndCulture',//
  
    // 政策学部
    '政策学科': 'PolicyStudies',//
  
    // グローバル地域文化学部
    'グローバル地域文化学科': 'GlobalAndRegionalStudies',//
  
    // 理工学部
    'インテリジェント情報工学科': 'IntelligentInformationEngineering',//
    '情報システムデザイン学科': 'InformationSystemDesign',//
    '電気工学科': 'ElectricalEngineering',//
    '電子工学科': 'ElectronicEngineering',//
    '機械システム工学科': 'MechanicalSystemsEngineering',//
    '機械理工学科': 'MechanicalEngineeringAndScience',//
    '数理システム学科': 'MathematicalSystems',//
    '化学システム創成工学科': 'ChemicalEngineering',//
    '環境システム学科': 'EnvironmentalSystemsScience',//
    '機能分子・生命化学科': 'FunctionalMolecularAndLifeChemistry',//
  
    // 生命医科学部
    '医工学科': 'BiomedicalEngineering',//
    '医情報学科': 'MedicalInformationSystems',//
    '医生命システム学科': 'BiomedicalSystems',//
  
    // スポーツ健康科学部
    'スポーツ健康科学科': 'SportsAndHealthScience',//
  
    // 文化情報学部
    '文化情報学科': 'CultureAndInformationScience',//
  
    // 心理学部
    '心理学科': 'Psychology',//
  
    // グローバル・コミュニケーション学部
    'グローバル・コミュニケーション学科(英語コース)': 'GlobalCommunicationEnglish',//
     'グローバル・コミュニケーション学科(中国語コース)':'GlobalCommunicationChinese',//
      'グローバル・コミュニケーション学科(日本語コース)':'GlobalCommunicationJapanese',//
  
    // 学芸学部
    '音楽学科': 'Music',
    'メディア創造学科': 'MediaCreation',
    '国際教養学科': 'InternationalCulturalStudies',
  
    // 現代社会学部
    '社会システム学科': 'SocialSystems',
    '現代こども学科': 'ContemporaryChildStudies',
  
    // 薬学部
    '医療薬学科': 'PharmaceuticalSciences',
  
    // 看護学部
    '看護学科': 'Nursing',
  
    // 生活科学部
    '人間生活学科': 'HumanLifeStudies',
    '食物科学専攻': 'FoodScience',
    '管理栄養士専攻': 'RegisteredDietitian',
  
    // 表象文化学部
    '日本語日本文学科': 'JapaneseLiteratureAndLanguage',
    '英語英文学科': 'EnglishLiteratureAndLanguage',

    '同志社科目': 'DoshishaSubjects',
    'キャリア形成支援科目': 'CareerFormationSupportSubjects',
    '国際教養科目': 'InternationalLiberalArtsSubjects',
    'クリエイティブジャパン科目': 'CreativeJapanSubjects',
    '人文科学系科目': 'HumanitiesSubjects',
    '社会科学系科目':'SocialScienceCourses',
    '自然人間科学系科目': 'NaturalAndHumanSciencesSubjects',
    '複合領域科目': 'InterdisciplinarySubjects',
    'プロジェクト科目': 'ProjectSubjects',
    '保健体育科目': 'HealthAndPhysicalEducationSubjects',
  };
  
  export const faculties = {
    '同志社': [
      '文学部', '神学部', '法学部', '経済学部', '商学部', '社会学部', '政策学部', 'グローバル地域文化学部',
      '理工学部', '生命医科学部', 'スポーツ健康科学部', '文化情報学部', '心理学部', 'グローバル・コミュニケーション学部'
    ],
    '同女': [
      '学芸学部', '現代社会学部', '薬学部', '看護学部', '生活科学部', '表象文化学部'
    ]
  };
  
  export const departments = {
    '文学部': ['英文学科', '哲学科', '文化史学科', '国文学科', '美学芸術学科'],
    '神学部': ['神学科'],
    '法学部': ['政治学科', '法律学科'],
    '経済学部': ['経済学科'],
    '商学部': ['商学科'],
    '社会学部': ['社会学科', '社会福祉学科', 'メディア学科', '産業関係学科', '教育文化学科'],
    '政策学部': ['政策学科'],
    'グローバル地域文化学部': ['グローバル地域文化学科'],
    '理工学部': [
      'インテリジェント情報工学科', '情報システムデザイン学科', '電気工学科', '電子工学科', '機械システム工学科',
      '機械理工学科', '数理システム学科', '化学システム創成工学科', '環境システム学科', '機能分子・生命化学科'
    ],
    '生命医科学部': ['医工学科', '医情報学科', '医生命システム学科'],
    'スポーツ健康科学部': ['スポーツ健康科学科'],
    '文化情報学部': ['文化情報学科'],
    '心理学部': ['心理学科'],
    'グローバル・コミュニケーション学部': ['グローバル・コミュニケーション学科(英語コース)', 'グローバル・コミュニケーション学科(中国語コース)', 'グローバル・コミュニケーション学科(日本語コース)'],
    '学芸学部': ['音楽学科（声楽コース）', '音楽学科（鍵盤楽器コース）', '音楽学科（管弦打楽器コース）', '音楽学科（音楽科専攻）', 'メディア創造学科', '国際教養学科'],
    '現代社会学部': ['社会システム学科', '現代こども学科'],
    '薬学部': ['医療薬学科'],
    '看護学部': ['看護学科'],
    '生活科学部': ['人間生活学科', '食物科学専攻', '管理栄養士専攻'],
    '表象文化学部': ['日本語日本文学科', '英語英文学科']
  };
  
  export const category = {
    "美学芸術学科": ["必修科目", "選択1A", "選択1B", "選択1C"],
    "国文学科": ["必修科目", "選択1A", "選択1B", "選択1C"],
    "英文学科": ["必修科目", "必修演習科目", "必修セミナー科目", "必修卒論科目", "必修リスニング科目", "必修スピーキング科目", "必修リーディング科目", "必修ライティング科目", "必修オーラルコミュニケーション科目", "選択1A", "選択1B", "選択1C", "選択1D", "選択1E", "選択1F"],
    "哲学科": ["必修科目", "選択1A", "選択1B", "選択1C", "選択1D"],
    "文化史学科": ["共通必修科目", "日本文化史必修科目", "西洋・東洋文化史必修科目", "選択1A", "選択1B", "選択1C", "選択1D", "選択科目Ⅱ", "自由科目"],
    "神学科": ["必修科目", "聖書学", "キリスト教史", "組織神学", "実践神学", "キリスト教文化学", "イスラーム学", "イスラーム文化学", "古代近東学", "ユダヤ学", "宗教学"],
    "政治学科": ["入門科目", "基礎科目", "3類A群", "3類B群", "3類C群", "隣接分野科目", "演習科目", "文献研究科目", "特殊講義A", "特殊講義B", "特殊演習"],
    "法律学科": ["入門科目", "基礎科目", "3類A群", "文献研究科目", "演習科目", "法曹養成プログラム科目", "副専攻科目", "司法特講", "特殊講義A", "特殊講義B", "特殊演習"],
    "経済学科": ["導入科目", "基礎科目", "基幹科目", "演習科目", "2年次演習関連科目", "3年次演習関連科目", "エコノミクス・ワークショップ・プライマリ科目", "エコノミクス・ワークショップ・アドバンスト科目", "2類科目", "3類科目"],
    "商学科": ["導入科目", "入門科目", "学系基礎科目1", "学系基礎科目2", "学系発展科目（経済・歴史）", "学系発展科目（商業・金融）", "学系発展科目（貿易・国際学）", "学系発展科目（企業・経営）", "学系発展科目（簿記・会計学）", "実践科目", "専門外国語科目", "演習科目", "隣接科目"],
    "社会学科": ["必修科目", "社会学講義A", "社会学講義B", "社会調査科目", "外国書購読", "学部共通科目"],
    "社会福祉学科": ["必修科目", "選択Ⅰ A", "選択Ⅰ B", "選択Ⅰ C", "選択Ⅰ D", "選択Ⅰ（学部共通科目）", "選択Ⅱ", "選択Ⅲ"],
    "メディア学科": ["必修科目", "選択Ⅰ A", "選択Ⅰ B", "選択Ⅰ C", "選択Ⅰ D", "選択Ⅰ（学部共通科目）", "選択Ⅱ", "選択Ⅲ"],
    "産業関係学科": ["必修科目", "選択Ⅰ（学科専門科目）", "選択Ⅰ（学部共通科目）", "選択Ⅱ", "選択Ⅲ"],
    "教育文化学科": ["コア科目X", "コア科目Y", "メジャー科目A", "メジャー科目B", "メジャー科目C", "メジャー科目D", "選択Ⅰ", "選択Ⅱ", "選択Ⅲ"],
    "政策学科": ["オリエンテーション科目", "基礎能力養成科目", "基礎科目", "導入科目A群", "導入科目B群", "展開科目A群", "展開科目B群", "展開科目C群", "展開科目D群", "選択Ⅲ", "国際教育インスティテュートA群-1", "国際教育インスティテュートA群-2", "国際教育インスティテュートB群", "卒業研究プロジェクト"],
    "グローバル地域文化学科": ["コース別必修科目","必修科目","卒論","選択必修A群", "選択必修B群", "選択必修C群", "共通選択A群", "ヨーロッパコース選択A群" ,"アジア・オセアニアコース選択A群","アメリカコース選択A群","ヨーロッパコース選択B群","アジア・オセアニアコース選択B群","アメリカコース選択B群", "選択C群", "選択D群"],
    "情報システムデザイン学科": ["必修科目", "A1-1a", "A1-1b", "A1-2", "A群2類", "B群2類", "C群"],
    "インテリジェント情報工学科": ["必修科目", "A1-1a", "A1-1b", "A1-1c", "A1-1d", "A1-2", "A群2類", "B群2類", "C群"],
    "電気工学科": ["必修科目", "A1-1a", "A1-1b", "A1-2", "A群2類", "B群2類"],
    "電子工学科": ["必修科目", "A1-1a", "A1-1b", "A1-2", "A群2類", "B群2類"],
    "機械システム工学科": ["必修科目", "A群1類", "A2-1", "A2-2", "B群2類", "C群"],
    "機能分子・生命化学科": ["必修科目", "A1-1", "A1-2", "A1-3", "A群2類", "B群1類", "B群2類"],
    "化学システム創成工学科": ["必修科目", "A群1類", "A群2類", "B群2類", "C群"],
    "環境システム学科": ["必修科目", "A群1類", "A2-1", "A2-2", "B群2類"],
    "数理システム学科": ["必修科目", "A群1類", "A群2類", "B群2類", "C群"],
    "機械理工学科": ["必修科目", "A群1類", "A2-1", "A2-2", "B群2類", "C群"],
    "医工学科": ["必修科目", "A1-1", "A1-2", "A2-1", "A2-2", "B群2類", "B群3類", "C群"],
    "医情報学科": ["必修科目", "A1-1a", "A1-1b", "A1-2", "A2-1", "A2-2", "B群2類", "B群3類", "C群"],
    "医生命システム学科": ["必修科目", "A群1類", "A群2類", "B群2類", "B群3類"],
    "スポーツ健康科学科": ["必修科目", "A群", "B群", "C群1類", "C群2類", "D群", "E群"],
    "文化情報学科": ["必修科目", "選択Ⅰ A", "選択Ⅰ B", "選択Ⅰ C"],
    "心理学科": ["必修科目", "A群", "B群", "C群", "D群", "E群"],
    "グローバル・コミュニケーション学科(英語コース)": ["必修科目", "選択1A", "選択1B", "選択1C", "選択1D", "選択1E", "選択1F", "選択科目Ⅱ"],
    "グローバル・コミュニケーション学科(中国語コース)": ["必修科目", "選択1A1類", "選択1A2類", "選択1B", "選択1C", "選択1D", "選択1E",  "選択Ⅱ類科目"],
    "グローバル・コミュニケーション学科(日本語コース)": ["必修科目", "選択1A", "選択1B", "選択1C", "選択1D", "選択1E", "選択科目Ⅱ"],
    "音楽学科（声楽コース）": ["基礎教育科目", "入門概論科目", "応用各論科目", "学部統合科目", "コース共通科目"],
    "音楽学科（鍵盤楽器コース）": ["基礎教育科目", "入門概論科目", "応用各論科目", "学部統合科目", "コース共通科目"],
    "音楽学科（管弦打楽器コース）": ["基礎教育科目", "入門概論科目", "応用各論科目", "学部統合科目", "コース共通科目"],
    "音楽学科（音楽科専攻）": ["基礎教育科目", "入門概論科目", "応用各論科目", "学部統合科目", "コース共通科目", "ゼミナール科目"],
    "メディア創造学科": ["基礎教育科目", "入門概論科目", "応用各論科目", "学部統合科目", "共通学芸科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
    "国際教養学科": ["基礎教育科目", "国際教養科目", "キャリアデザイン科目", "演習科目", "学部統合科目", "共通学芸科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
    "社会システム学科": ["基礎入門科目", "概論科目A", "概論科目B", "概論科目C", "概論科目D", "概論科目E", "応用各論（コース科目）", "応用各論（コミュニケーション科目）", "応用各論（総合キャリア科目）", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
    "現代こども学科": ["導入科目", "入門概論科目", "応用各論科目", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
    "医療薬学科": ["基礎教育科目", "入門概論科目", "応用各論科目（講義）", "応用各論科目（実験実習）", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI"],
    "看護学科": ["基礎教育科目", "入門概論科目", "応用各論科目（看護基盤）", "応用各論科目（看護展開）", "応用各論科目（看護探究）", "応用各論科目（臨地実習）", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI"],
    '人間生活学科': ["基礎教育科目", "入門概論科目", "応用各論科目", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
    '食物栄養科学（食物科学）': ["基礎教育科目（講義）", "基礎教育科目（実験実習）", "入門概論科目", "応用各論科目（講義）", "応用各論科目（実験実習）", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI"],
    '食物栄養科学（管理栄養士）': ["基礎教育科目（講義）", "基礎教育科目（実験実習）", "入門概論科目", "応用各論科目（講義）", "応用各論科目（実験実習）", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI"],
    "日本語日本文学科": ["表象文化科目", "導入科目", "基礎科目", "応用各論科目（基幹）", "応用各論科目(京都から世界へ)", "応用各論科目(表象と表現)", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
    "英語英文学科": ["表象文化科目", "英語スキル科目", "入門概論科目", "応用各論科目（専門英語）", "応用各論科目（基幹）", "応用各論科目（英語表現）", "応用各論科目（英語キャリア）", "応用各論科目（京都から世界へ）", "共通学芸科目", "キリスト教・同志社関係科目", "外国語", "スポーツ健康科目", "データサイエンス・AI", "ゼミナール科目"],
  };