import React from 'react';
import { Box, Typography, Button, List, ListItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const HelpPage = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            minHeight="100vh"
            sx={{ maxWidth: '800px' }} // 最大幅を設定して中央寄せ
        >
            <Button
                component={RouterLink}
                to="/"
                sx={{
                    position: 'fixed',
                    top: 16,
                    left: 16,
                    color: 'black',
                    borderRadius: 10,
                    '&:hover': {
                        color: 'gray', // ホバーカラーを指定
                    }
                }}
            >
                ホーム
            </Button>

            <Typography variant="h5" sx={{ mt: 8 }}>
                よくある質問
            </Typography>

            <List>
                <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6">
                        <strong>Q: 特定の科目が見つかりません。どうすれば良いですか？</strong>
                    </Typography>
                    <Typography paragraph sx={{ mt: 1 }}>
                        A: 投票データをまとめるため、同じ科目内容・同じ先生・同じ学期の科目は全て1つにまとめています。
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        ・経済学1-13・田中太郎・春
                    </Typography>
                    <Typography>
                        ・経済学1-14・田中太郎・春
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        は「経済学1-13・田中太郎・春」にまとめています。
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        ・経済学1-13・田中太郎・春
                    </Typography>
                    <Typography>
                        ・経済学1-15・田中太郎・秋
                    </Typography>
                    <Typography>
                        ・経済学2・田中太郎・春
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        は別々にしています。
                    </Typography>
                </ListItem>

                <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6">
                        <strong>Q: 科目情報はどのように更新されていますか？</strong>
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                        A: 科目情報は、大学の履修要項をもとに手動で追加・更新されています。自動更新ではなく、運営チームが定期的に情報を確認し、可能な限り最新の情報を提供しています。将来的には専門科目にも対応する予定です。
                    </Typography>
                </ListItem>

                <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6">
                        <strong>Q: アカウントを作成する必要がありますか？</strong>
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                        A: いいえ、このサービスを利用するためにアカウントを作成する必要はありません。全ての機能はアカウントなしでご利用いただけます。将来的にはアカウント登録が必須な機能も実装する予定です。
                    </Typography>
                </ListItem>

                <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6">
                        <strong>Q: 特定の科目について詳細な情報が見つからない場合はどうすればよいですか？</strong>
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ mt: 1 }}>
                        A: 科目に関する詳細な情報が見つからない場合は、以下のフォームから情報を送信してください。今後のアップデートで反映される可能性があります。
                    </Typography>
                    <Button
                        color="primary"
                        component="a"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfHnABrQ1SgAijhAeCtToWNp6isHZpyunD0aPovlI6n1Axl-Q/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 1, mb: 1 }}
                    >
                        科目情報を送信する
                    </Button>
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                お問合せ先
            </Typography>
            <Typography variant="h7" sx={{ mt: 1 }}>
                お問い合わせは{' '}
                <a href="mailto:support@milicone.com">
                    support@milicone.com
                </a>{' '}
                までお願いします。
            </Typography>
        </Box>
    );
};

export default HelpPage;