import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGeneralCourses } from '../config/context';

const GeneralHeader = () => {
  const {
    selectedUniversity,
    // setSelectedUniversity,
  } = useGeneralCourses();

  const isDoshisha = selectedUniversity === '同志社';
  const mainColor = isDoshisha ? '#800080' : '#9b59b6';
  const hoverColor = isDoshisha ? '#b83fb3' : '#b3a1c7';
  const navigate = useNavigate();


  const handleSignUpClick = () => {
      navigate('/courses');
  }

  // const handleSelectUniversity = async (university) => {
  //   setSelectedUniversity(university);
  // };



  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
      <Box display="flex" width="100%">
        <Typography
          sx={{
            fontWeight: 600,
            variant: 'h4',
            fontSize: 30,
            width: '100%'
          }}
        >
          (非公式) {selectedUniversity} 楽単まとめ
        </Typography>
        
      </Box>
      
      <Box flexGrow={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{
            width: 216,
            height: 46,
            fontSize: '17px',
            fontWeight: 600,
            mx: 1,
            backgroundColor: mainColor,
            color: 'white',
            borderRadius: '25px',
            padding: '10px 20px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: hoverColor,
            },
          }}
          onClick={handleSignUpClick}
        >
            専門科目を見る
        </Button>
      </Box>

      {/* <Box display="flex" sx={{ position: 'relative', alignItems: 'center' }}>
        <Box
          sx={{
            position: 'relative',
            width: '220px',
            height: '50px',
            backgroundColor: '#E0E0E0',
            borderRadius: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '49%',
              height: '80%',
              backgroundColor: mainColor,
              borderRadius: '25px',
              transition: 'transform 0.3s',
              zIndex: 0,
              transform: isDoshisha ? 'translateX(0)' : 'translateX(100%)',
            }}
            onClick={() => handleSelectUniversity(isDoshisha ? '同女' : '同志社')}
          ></Box>

          <Box
            sx={{
              zIndex: 1,
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: 700,
              width: '50%',
              textAlign: 'center',
              userSelect: 'none',
              color: isDoshisha ? 'white' : 'black',
            }}
            onClick={() => handleSelectUniversity('同志社')}
          >
            同志社
          </Box>
          <Box
            sx={{
              zIndex: 1,
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: 700,
              width: '50%',
              textAlign: 'center',
              userSelect: 'none',
              color: !isDoshisha ? 'white' : 'black',
            }}
            onClick={() => handleSelectUniversity('同女')}
          >
            同女
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

export default GeneralHeader;