import React, { useState } from 'react';
import { Box, Typography, Paper, Chip, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GeneralCourseModal from './GeneralCourseModal';
import { useGeneralCourses } from '../config/context';

const GeneralCourseListPage = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { courses, selectedField, isMobile } = useGeneralCourses();
  const navigate = useNavigate();

  const handleOpenCourse = (course) => {
    if (isMobile) {
      navigate(`/course/${course.id}`, { state: { course } });
    } else {
      setSelectedCourse(course);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCourse(null);
  };

  const calculateStatus = (course) => {
    const votes = course.votes || { easy: 0, normal: 0, hard: 0 };
    const totalVotes = (votes.easy || 0) + (votes.normal || 0) + (votes.hard || 0);

    if (totalVotes === 0) return 'データなし';

    const hardPercentage = (votes.hard / totalVotes) * 100;
    const easyPercentage = (votes.easy / totalVotes) * 100;

    if (hardPercentage > 20) {
      return 'hard';
    } else if (easyPercentage > 50) {
      return 'easy';
    } else {
      return 'normal';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'easy':
        return '楽単';
      case 'hard':
        return 'エグ単';
      case 'normal':
        return '普通';
      default:
        return 'データなし';
    }
  };

  const handleHelpClick = () => {
    navigate('/help');
  };

  const courseList = courses || [];

  const renderCourses = () => (
    <Box my={2} display="flex" flexWrap="wrap" gap={1} justifyContent={isMobile ? 'space-between' : 'flex-start'}>

      <Button
        variant="text"
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          color: 'black',
          textTransform: 'none',
        }}
        onClick={handleHelpClick}
      >
        ヘルプ
      </Button>


      {courseList.map((course, index) => (
        <Paper
          key={index}
          onClick={() => handleOpenCourse(course)}
          sx={{
            padding: isMobile ? '5px' : '20px',
            textAlign: 'left',
            cursor: 'pointer',
            position: 'relative',
            borderRadius: '20px',
            minHeight: '60px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: isMobile ? 'calc(50% - 20px)' : '300px',  // モバイル画面では幅を50%に
            border: `3px solid ${calculateStatus(course) === 'easy'
              ? '#1976d2'
              : calculateStatus(course) === 'hard'
                ? '#d32f2f'
                : calculateStatus(course) === 'normal' ? '#00cc00' : 'transparent'
              }`,
            '&:hover': {
              boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
            },
          }}
          elevation={3}
        >
          <Typography variant={isMobile ? "h7" : "h6"} sx={{ fontWeight: 700 }}>{course.courseName}</Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>{course.lecturer}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 'auto' }}>
            <Typography variant={isMobile ? "caption" : "body2"} sx={{ mt: 1 }}>
              {course.place}
            </Typography>

            {calculateStatus(course) === 'データなし' ? (
              <></>
            ) : (
              <Chip
                label={getStatusLabel(calculateStatus(course))}
                sx={{
                  backgroundColor: `${calculateStatus(course) === 'easy'
                    ? '#1976d2'
                    : calculateStatus(course) === 'hard'
                      ? '#d32f2f'
                      : calculateStatus(course) === 'normal' ? '#00cc00' : 'transparent'
                    }`,
                  color: 'white',
                  fontSize: isMobile ? '14px' : '20px',
                  fontWeight: '600',
                  padding: isMobile ? '0px' : '5px',
                  ml: 1, // course.placeとstatusChipの間にマージンを追加
                }}
              />
            )}
          </Box>
        </Paper>
      ))}
    </Box>
  );

  return (
    <Box>
      {renderCourses()}
      {courseList.length === 0 && (
        <Box display="center" justifyContent="center">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography

              sx={{
                textAlign: 'center',
                color: 'purple',
                mt: 5,
                fontSize: 20,
                fontWeight: 700
              }}
              variant="subtitle2"
            >
              {selectedField === "" ? "科目分類を選択してください" : "科目データがありません"}
            </Typography>

            {/* 科目の説明部分 */}
            <Typography
              sx={{
                mt: 10,
                fontSize: isMobile ? 16 : 20,
                fontWeight: 700
              }}
              variant="subtitle2"
            >
              クリエイティブジャパン：
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? 16 : 20,
              }}
              variant="subtitle2"
            >
              Japan、京都科目、伝統芸能
            </Typography>

            <Typography
              sx={{
                mt: 2,
                fontSize: isMobile ? 16 : 20,
                fontWeight: 700
              }}
              variant="subtitle2"
            >
              人文科学科目：
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? 16 : 20,
              }}
              variant="subtitle2"
            >
              宗教・哲学・倫理・論理・芸術・歴史・文学
            </Typography>

            <Typography
              sx={{
                mt: 2,
                fontSize: isMobile ? 16 : 20,
                fontWeight: 700
              }}
              variant="subtitle2"
            >
              社会科学科目：
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? 16 : 20,
              }}
              variant="subtitle2"
            >
              法学・政治・経済・商学
            </Typography>

            <Typography
              sx={{
                mt: 2,
                fontSize: isMobile ? 16 : 20,
                fontWeight: 700
              }}
              variant="subtitle2"
            >
              自然人間科学科目：
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? 16 : 20,
              }}
              variant="subtitle2"
            >
              DS・AI・物理数学化学・地球宇宙・生命・環境・自然・心理
            </Typography>
          </Box>
        </Box>
      )}
      {selectedCourse && !isMobile && (
        <GeneralCourseModal
          open={modalOpen}
          handleClose={handleCloseModal}
          course={selectedCourse}
        />
      )}
    </Box>
  );
};

export default GeneralCourseListPage;